import * as Yup from 'yup';

export const signInValidationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    password: Yup.string()
        .required('Password is required')
})
export const forgetPasswordSchema = Yup.object().shape({
    emailValue: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
})
export const registrationInviteSchema = Yup.object().shape({
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(
            /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])/,
            'Password must contain at least one letter, one special character, and one numeric character'
        ),
    reTypePassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is required'),
})
export const dealRegistrationSchema = Yup.object().shape({
    deal_name: Yup.string()
        .required('Deal name is required')
        .matches(/^(?=.*\S)[a-zA-Z0-9\s_-]*$/, 'Deal name must contain at least one non-space character and consist of only letters, numbers, spaces, underscores, and hyphens')
        .min(2, 'Deal name must be at least 2 characters')
        .max(255, 'Deal name must not exceed 255 characters'),
    deal_value: Yup.number()
        .required('Deal value is required')
        .typeError('Invalid amount')
        .positive('Deal value must be positive')
        .integer('Deal value must be an integer'),
    deal_status: Yup.object()
        .nullable()
        .required('Deal status is required'),
    customer_email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    first_name: Yup.string()
        .matches(/^[A-Za-z\s]*[^\s][A-Za-z\s]*$/, 'First name must contain at least one non-space character and consist of only alphabets and spaces')
        .min(2, 'First name must be at least 2 character long')
        .max(50, 'First name must be at most 50 characters long')
        .required('First name is required'),
    last_name: Yup.string()
        .matches(/^[A-Za-z\s]*[^\s][A-Za-z\s]*$/, 'Last name must contain at least one non-space character and consist of only alphabets and spaces')
        .min(2, 'Last name must be at least 2 character long')
        .max(50, 'Last name must be at most 50 characters long')
        .required('Last name is required'),
    company_name: Yup.object()
        .nullable()
        .required('Company name is required'),
    lead_source: Yup.string()
        .nullable()
        .required('Lead source is required'),
    description: Yup.string()
        .nullable()
        .required('Description required'),
    designation: Yup.string().default('').required('Designation required'),
    phone_number: Yup.string()
        .when('lead_country_code', {
            is: (countryCode) => countryCode && countryCode.countryValue === '+91', // Example condition for the US country code
            then: Yup.string().matches(/^\d{10}$/, 'Invalid mobile number').required('Phone number is required'),
            // Add more conditions for other country codes as needed
            otherwise: Yup.string().matches(/^\d{10,15}$/, 'Invalid mobile number').required('Phone number is required')
        }),
})
export const dealCompanyregistrationSchema = Yup.object().shape({
    deal_name: Yup.string()
        .required('Deal name is required')
        .matches(/^(?=.*\S)[a-zA-Z0-9\s_-]*$/, 'Deal name must contain at least one non-space character and consist of only letters, numbers, spaces, underscores, and hyphens')
        .min(2, 'Deal name must be at least 2 characters')
        .max(255, 'Deal name must not exceed 255 characters'),
    deal_value: Yup.number()
        .required('Deal value is required')
        .typeError('Invalid amount')
        .positive('Deal value must be positive')
        .integer('Deal value must be an integer'),
    deal_status: Yup.object()
        .nullable()
        .required('Deal status is required'),
    customer_email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    first_name: Yup.string()
        .matches(/^[A-Za-z\s]*[^\s][A-Za-z\s]*$/, 'First name must contain at least one non-space character and consist of only alphabets and spaces')
        .min(2, 'First name must be at least 2 character long')
        .max(50, 'First name must be at most 50 characters long')
        .required('First name is required'),
    last_name: Yup.string()
        .matches(/^[A-Za-z\s]*[^\s][A-Za-z\s]*$/, 'Last name must contain at least one non-space character and consist of only alphabets and spaces')
        .min(2, 'Last name must be at least 2 character long')
        .max(50, 'Last name must be at most 50 characters long')
        .required('Last name is required'),
    company_name: Yup.object()
        .nullable()
        .required('Company name is required'),
    lead_source: Yup.string()
        .required('Lead source is required'),
    description: Yup.string()
        .required('Description is required'),
    country: Yup.object()
        .nullable()
        .required('Country is required'),
    industry: Yup.object()
        .nullable()
        .required('Industry is required'),
    company_website: Yup.string()
        .url('Invalid URL format')
        .required('Company website is required'),
    designation: Yup.string().default('').required('Designation required'),
    phone_number: Yup.string()
        .when('lead_country_code', {
            is: (countryCode) => countryCode && countryCode.countryValue === '+91', // Example condition for the US country code
            then: Yup.string().matches(/^\d{10}$/, 'Invalid mobile number').required('Phone number is required'),
            // Add more conditions for other country codes as needed
            otherwise: Yup.string().matches(/^\d{10,15}$/, 'Invalid mobile number').required('Phone number is required')
        }),
})
export const leadUpdateSchema = Yup.object().shape({
    deal_name: Yup.string()
        .required('Deal name is required')
        .matches(/^(?=.*\S)[a-zA-Z0-9\s_-]*$/, 'Deal name must contain at least one non-space character and consist of only letters, numbers, spaces, underscores, and hyphens')
        .min(2, 'Deal name must be at least 2 characters')
        .max(255, 'Deal name must not exceed 255 characters'),
    deal_value: Yup.number()
        .required('Deal value is required')
        .typeError('Invalid amount')
        .positive('Deal value must be positive')
        .integer('Deal value must be an integer'),
    deal_currency: Yup.string()
        .required('Deal currency is required'),
    deal_status: Yup.string()
        .required('Deal status is required'),
    customer_email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    first_name: Yup.string()
        .matches(/^[A-Za-z\s]*[^\s][A-Za-z\s]*$/, 'First name must contain at least one non-space character and consist of only alphabets and spaces')
        .min(2, 'First name must be at least 2 character long')
        .max(50, 'First name must be at most 50 characters long')
        .required('First name is required'),
    last_name: Yup.string()
        .matches(/^[A-Za-z\s]*[^\s][A-Za-z\s]*$/, 'Last name must contain at least one non-space character and consist of only alphabets and spaces')
        .min(2, 'Last name must be at least 2 character long')
        .max(50, 'Last name must be at most 50 characters long')
        .required('Last name is required'),
    company_name: Yup.object()
        .nullable()
        .required('Company name is required'),
    lead_source: Yup.string()
        .required('Lead source is required'),
    description: Yup.string()
        .required('Description is required'),
    company_website: Yup.string()
        .url('Invalid URL format')
})
export const contactUpdateSchema = Yup.object().shape({
    customer_email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    first_name: Yup.string()
        .matches(/^[A-Za-z\s]*[^\s][A-Za-z\s]*$/, 'First name must contain at least one non-space character and consist of only alphabets and spaces')
        .min(2, 'First name must be at least 2 character long')
        .max(50, 'First name must be at most 50 characters long')
        .required('First name is required'),
    last_name: Yup.string()
        .matches(/^[A-Za-z\s]*[^\s][A-Za-z\s]*$/, 'Last name must contain at least one non-space character and consist of only alphabets and spaces')
        .min(2, 'Last name must be at least 2 character long')
        .max(50, 'Last name must be at most 50 characters long')
        .required('Last name is required'),
    company_name: Yup.object()
        .nullable(),
})
export const companyUpdateSchema = Yup.object().shape({
    company_website: Yup.string()
        .url('Invalid URL format').required('Company website is required').nullable(),
    company_name: Yup.object()
        .nullable(),
    country: Yup.object()
        .nullable(),
    industry: Yup.object()
        .nullable(),
})
export const changePasswordSchema = Yup.object().shape({
    currentPasswordText: Yup.string()
        .required('Current password is required'),
        newPasswordText: Yup.string()
            .required('New password is required')
            .min(8, 'Password must be at least 8 characters')
            .matches(
                /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])/,
                'Password must contain at least one letter, one special character, and one numeric character'
            ),
        reTypePasswordText: Yup.string()
            .oneOf([Yup.ref('newPasswordText'), null], 'Passwords must match')
            .required('Confirm password is required'),
})

export const personalAdminEditSchema = Yup.object().shape({
    company_website: Yup.string()
        .transform((value, originalValue) => originalValue === null ? '' : value)
        .url('Invalid URL format'),
    company_name: Yup.object()
        .nullable(),
    country: Yup.object()
        .nullable(),
    name: Yup.string()
        .nullable()
        .default('') // Default to an empty string if null
        .required('Name is required')
        .matches(/^[a-zA-Z\s]*$/, 'Name must only contain letters and spaces'),
    designation: Yup.string()
        .nullable()
        .default('') // Default to an empty string if null
        .required('Designation is required')
        .matches(/^[a-zA-Z0-9\s]*$/, 'Designation must only contain letters, numbers, and spaces'),
    phone_number: Yup.string()
        .when('countryCode', {
            is: (countryCode) => countryCode === '+91',
            then: Yup.string().matches(/^\d{10}$/, 'Invalid mobile number').required('Phone number is required'),
            otherwise: Yup.string().matches(/^\d{10,15}$/, 'Invalid mobile number').required('Phone number is required')
        }),
    countryCode:Yup.string().required('Country code is required')
})

export const personalMemberEditSchema = Yup.object().shape({
    name: Yup.string()
        .nullable()
        .default('') // Default to an empty string if null
        .required('Name is required')
        .matches(/^[a-zA-Z\s]*$/, 'Name must only contain letters and spaces'),
    phone_number: Yup.string()
        .when('countryCode', {
            is: (countryCode) => countryCode === '+91',
            then: Yup.string().matches(/^\d{10}$/, 'Invalid mobile number').required('Phone number is required'),
            otherwise: Yup.string().matches(/^\d{10,15}$/, 'Invalid mobile number').required('Phone number is required')
        }),
    countryCode:Yup.string().required('Country code is required')
})

export const emailValidationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required')
})
export const memeberInviteSchema = Yup.object().shape({
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(
            /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])/,
            'Password must contain at least one letter, one special character, and one numeric character'
        ),
    first_name: Yup.string()
        .matches(/^[A-Za-z\s]*[^\s][A-Za-z\s]*$/, 'First name must contain at least one non-space character and consist of only alphabets and spaces')
        .min(2, 'First name must be at least 2 character long')
        .max(50, 'First name must be at most 50 characters long')
        .required('First name is required'),
    last_name: Yup.string()
        .matches(/^[A-Za-z\s]*[^\s][A-Za-z\s]*$/, 'Last name must contain at least one non-space character and consist of only alphabets and spaces')
        .min(2, 'Last name must be at least 2 character long')
        .max(50, 'Last name must be at most 50 characters long')
        .required('Last name is required'),
})

export const leadRegistrationSchema = Yup.object().shape({
    customer_email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    first_name: Yup.string()
        .matches(/^[A-Za-z\s]*[^\s][A-Za-z\s]*$/, 'First name must contain at least one non-space character and consist of only alphabets and spaces')
        .min(2, 'First name must be at least 2 character long')
        .max(50, 'First name must be at most 50 characters long')
        .required('First name is required'),
    last_name: Yup.string()
        .matches(/^[A-Za-z\s]*[^\s][A-Za-z\s]*$/, 'Last name must contain at least one non-space character and consist of only alphabets and spaces')
        .min(2, 'Last name must be at least 2 character long')
        .max(50, 'Last name must be at most 50 characters long')
        .required('Last name is required'),
    lead_country_code: Yup.object()
        .nullable()
        .required('Country Code is required'),
    phone_number: Yup.string()
        .when('lead_country_code', {
            is: (countryCode) => countryCode && countryCode.countryValue === '+91', // Example condition for the US country code
            then: Yup.string().matches(/^\d{10}$/, 'Invalid mobile number').required('Phone number is required'),
            // Add more conditions for other country codes as needed
            otherwise: Yup.string().matches(/^\d{10,15}$/, 'Invalid mobile number').required('Phone number is required')
        }),
    company_name: Yup.object().shape({
        name: Yup.string().required('Company name is required')
    }),
    designation: Yup.object()
        .nullable()
        .required('Designation is required'),
    lead_country: Yup.object()
        .nullable()
        .required('Country is required'),
})

export const leadCompanyregistrationSchema = (newCompany: boolean) => Yup.object().shape({
    customer_email: Yup.string()
        .email('Please provide a valid email address.')
        .required('Please provide a valid email address.'),
    first_name: Yup.string()
        .matches(/^[A-Za-z ]*$/, 'First name must contain at least one non-space character and consist of only alphabets and spaces')
        .min(2, 'First name must be at least 2 character long')
        .max(50, 'First name must be at most 50 characters long')
        .required("First name required"),
    last_name: Yup.string()
        .matches(/^[A-Za-z ]*$/, 'Last name must contain at least one non-space character and consist of only alphabets and spaces')
        .min(2, 'Last name must be at least 2 character long')
        .max(50, 'Last name must be at most 50 characters long')
        .required("Last name required"),
    lead_country_code: Yup.object()
        .nullable()
        .required('Country Code is required'),
    phone_number: Yup.string()
        .when('lead_country_code', {
            is: (lead_country_code) => lead_country_code.label === '+91', // Example condition for the US country code
            then: Yup.string().matches(/^\d{10}$/, 'Invalid mobile number').required('Phone number is required'),
            // Add more conditions for other country codes as needed
            otherwise: Yup.string().matches(/^\d{10,15}$/, 'Invalid mobile number').required('Phone number is required')
        }),
    company_name: Yup.object().shape({
        name: Yup.string().required('Company name is required')
    }),
    country:newCompany? Yup.object()
        .nullable()
        .required('Country is required'):Yup.object().nullable(),
    industry: newCompany ? Yup.object()
        .nullable()
        .required('Industry is required') : Yup.object().nullable(),
    company_website: Yup.string().nullable().url('Invalid URL format'),
    designation: Yup.string().required('Designation is required'),
    employees: Yup.object().nullable(),

    product_type: Yup.object().shape({
        label: Yup.string().required('Label is required'),
        value: Yup.string().required('Value is required')
      }).required('Product type is required').nullable(false),
    best_describe: Yup.object().shape({
        label: Yup.string().required('Label is required'),
        value: Yup.string().required('Value is required')
      }).required('Product type is required').nullable(false),
    budget: Yup.object().shape({
        label: Yup.string().required('Label is required'),
        value: Yup.string().required('Value is required')
      }).required('Product type is required').nullable(false),
    contact_date: Yup.string(),
    platform_type: Yup.string(),
    software_delivered: Yup.object().shape({
        label: Yup.string().required('Label is required'),
        value: Yup.string().required('Value is required')
      }).required('Product type is required').nullable(false),
    product_idea: Yup.string().required("Product idea required"),
    app_category: Yup.object().shape({
        label: Yup.string().required('Label is required'),
        value: Yup.string().required('Value is required')
      }).required('Product type is required').nullable(false),
    idea_stage: Yup.object().shape({
        label: Yup.string().required('Label is required'),
        value: Yup.string().required('Value is required')
      }).required('Product type is required').nullable(false),
    description: Yup.string().required("Additional notes required"),
    lead_country:newCompany?Yup.object().shape({
        label: Yup.string(),
        value: Yup.string()
      }).nullable(): Yup.object().shape({
        label: Yup.string().required('Label is required'),
        value: Yup.string().required('Value is required')
      }).nullable().required('Country is required'),

})

export const payoutSettingsSchema = Yup.object().shape({

    account_holder_name: Yup.string()
        .matches(/^[A-Za-z\s]*[^\s][A-Za-z\s]*$/, 'Account holder name must contain at least one non-space character and consist of only alphabets and spaces')
        .min(1, 'Account holder name must be at least 1 character long')
        .max(255, 'Account holder name must be at most 255 characters long')
        .required('Account holder name is required'),
    bank_name: Yup.string()
        .matches(/^(?=.*[^\s])[A-Za-z0-9\s]+$/, 'Bank name must contain at least one non-space character and consist of only alphanumeric characters and spaces')
        .min(2, 'Bank name must be at least 2 characters long')
        .max(50, 'Bank name must be at most 50 characters long')
        .required('Bank name is required'),
    account_number: Yup.string()
        .matches(/^[0-9]+$/, 'Account number must contain only numbers')
        .required('Account number is required'),
    confirm_account_number: Yup.string()
        .matches(/^[0-9]+$/, 'Account number must contain only numbers')
        .required('Confirm account number is required')
        .oneOf([Yup.ref('account_number'), null], 'Account numbers must match'),
    ifsc_type: Yup.object()
        .nullable()
        .required('Bank code required'),
    ifsc_code: Yup.string().when('ifsc_type', {
        is: (val) => val && val.value === 'IFSC code',
        then: Yup.string()
            .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, 'Invalid IFSC code')
            .required('IFSC code is required'),
        otherwise: Yup.string().notRequired(), // Make it not required when ifsc_type is empty or not 'IFSC Code'
    }).when('ifsc_type', {
        is: (val) => val && val.value === 'IBAN code',
        then: Yup.string()
            .matches(/^[A-Za-z0-9]{15,34}$/, 'Invalid IBAN code')
            .required('IBAN code is required'),
        otherwise: Yup.string().notRequired(), // Make it not required when ifsc_type is empty or not 'IBAN'
    }).when('ifsc_type', {
        is: (val) => val && val.value === 'Swift code',
        then: Yup.string()
            .matches(/^[A-Za-z0-9]{8}$|^[A-Za-z0-9]{11}$/, 'Invalid SWIFT code')
            .required('SWIFT code is required'),
        otherwise: Yup.string().notRequired(), // Make it not required when ifsc_type is empty or not 'Swift'
    }),
});
export const currencySettingsSchema = Yup.object().shape({
    country: Yup.object()
        .nullable()
        .required('Bank country/region is required'),
    currency: Yup.object()
        .nullable()
        .required('Currency is required'),
});

export const updatePayoutSettingsSchema = Yup.object().shape({
    account_holder_name: Yup.string()
        .matches(/^[A-Za-z\s]*[^\s][A-Za-z\s]*$/, 'Account holder name must contain at least one non-space character and consist of only alphabets and spaces')
        .min(1, 'Account holder name must be at least 1 character long')
        .max(255, 'Account holder name must be at most 255 characters long')
        .required('Account holder name is required'),
    bank_name: Yup.string()
        .matches(/^(?=.*[^\s])[A-Za-z0-9\s]+$/, 'Bank name must contain at least one non-space character and consist of only alphanumeric characters and spaces')
        .min(2, 'Bank name must be at least 2 characters long')
        .max(50, 'Bank name must be at most 50 characters long')
        .required('Bank name is required'),
    account_number: Yup.string()
        .matches(/^[0-9]+$/, 'Account number must contain only numbers')
        .required('Account number is required'),
    ifsc_type: Yup.object()
        .nullable()
        .required('Bank code required'),
    ifsc_code: Yup.string().when('ifsc_type', {
        is: (val) => val && val.value === 'IFSC code',
        then: Yup.string()
            .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, 'Invalid IFSC code')
            .required('IFSC code is required'),
        otherwise: Yup.string().notRequired(), // Make it not required when ifsc_type is empty or not 'IFSC Code'
    }).when('ifsc_type', {
        is: (val) => val && val.value === 'IBAN code',
        then: Yup.string()
            .matches(/^[A-Za-z0-9]{15,34}$/, 'Invalid IBAN code')
            .required('IBAN code is required'),
        otherwise: Yup.string().notRequired(), // Make it not required when ifsc_type is empty or not 'IBAN'
    }).when('ifsc_type', {
        is: (val) => val && val.value === 'Swift code',
        then: Yup.string()
            .matches(/^[A-Za-z0-9]{8}$|^[A-Za-z0-9]{11}$/, 'Invalid SWIFT code')
            .required('SWIFT code is required'),
        otherwise: Yup.string().notRequired(), // Make it not required when ifsc_type is empty or not 'Swift'
    })
});

export const raiseInvoiceValidationSchema = Yup.object().shape({
    comission_amount: Yup.string()
        .required('Commission amount is required'),
    invoice_amount: Yup.string()
        .test('is-not-empty', 'Invoice amount is required', function (value) {
            return value !== undefined && value !== null && value !== '';
        })
        .matches(/^\d+(\.\d{1,2})?$/, 'Invoice amount must be a valid number with up to 2 decimal places')
        .matches(/^\S+$/, 'Invoice amount must not contain empty spaces')
        .test('is-less-than-commission-amount', 'The value provided is larger than your original commission value', function (value) {
            const commissionAmount = this.resolve(Yup.ref('comission_amount'));
            return parseFloat(value) <= parseFloat(commissionAmount);
        })
        .required('Invoice amount is required')
})