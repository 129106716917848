import React from "react";
// Customizable Area Start
import { Box, Button, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TextField, InputAdornment, Modal, Menu, MenuItem, IconButton, Tooltip, Avatar } from "@material-ui/core";
import { closeIcon, deleteIcon, downloadIcon, emptySvg, searchIcon, leadsGraphBars, emptyAssets } from "./assets";
import { downArrow } from "../../assetsdashboard/src/assets";
import { calendarFilterArray, capitalizeFirstLetter, getCalendarByValue, getCheckBoxIcon, getCheckMarkIcon, getColorsForStatus, getCurrencyValue, getDashboardValues, getDateFormat, getDateRangeText, getIntitals, getLabelByValue, getLeadName, getNullValues, getPageCount, getStartAndEndDates, getToken, isApplicationNotReloaded, leadsFilterList } from "../../../components/src/commonUsage";
import Pagination from '@material-ui/lab/Pagination';
import { CSVLink } from "react-csv";
import TableSkeleton from "../../../components/src/TableSkeleton";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DoughnutGraph from "../../../components/src/doughnutGraph";
import LeadsPageLineGraph from "../../../components/src/leadsPageLineGraph";
import { checkMark } from "../../companycontactpage2/src/assets";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import 'react-calendar/dist/Calendar.css';
import moment from "moment";
import Skeleton from "@material-ui/lab/Skeleton";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TotalLeadsData(props: any) {
  return (
    <div className="tab-head-wrapper">
      <div className="tab-title">Total</div>
      <div className="tab-count">
        <span className="counter">{props.count}</span>
        <span className="duration">{props.calendar}</span>
      </div>
    </div>
  )
}

function OpenLeadsData(props: any) {
  return (
    <div className="tab-head-wrapper">
      <div className="tab-title">Leads and Opportunities</div>
      <div className="tab-count">
        <span className="counter">{props.count}</span>
        <span className="duration">{props.calendar}</span>
      </div>
    </div>
  )
}

function ClosedLeadsData(props: any) {
  return (
    <div className="tab-head-wrapper">
      <div className="tab-title">Closed Won/Lost</div>
      <div className="tab-count">
        <span className="counter">{props.count}</span>
        <span className="duration">{props.calendar}</span>
      </div>
    </div>
  )

}
import { trackEvent } from "../../../components/src/analytics";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End
import LeadManagementController, {
  Props
} from "./LeadManagementController";
import SideNav from "../../../components/src/SideNav";
import TopNavBar from "../../../components/src/TopNavBar";
import './css/styles.css'

export default class LeadDashboard extends LeadManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    if(await getStorageData("leavedPage") && isApplicationNotReloaded()){
      trackEvent("prm_home_page_visited","user lands on the the PRM portal",{dewey_code:"B3.1",flow:1})
      await removeStorageData("leavedPage")
    }
    window.addEventListener('unload', async()=>{
      await setStorageData("leavedPage","true")
    });
    if (getToken('company_id') !== null) {
      this.setState({
        query: getToken('company_name'),
        searchValue: getToken('company_name'),
        companyId: getToken('company_id')
      }, () => {
        this.getLeads('filter');
      })
    } else {
      this.getLeads('normal')
    }
    trackEvent('leads_and_deals_accessed', "User clicks on Leads and Deals section within the left navigation pane", {dewey_code:"B3.4",flow:4});
  }

  leadInfo=()=>{
    return this.state.leadInfo.leads.map((row: any, index: number) => (
      <TableRow key={index}>
        <TableCell className="lead-table-data-company checkbox-cell">
          <Checkbox
            data-test-id={`selectSingleDeal${index}`}
            size="small"
            icon={getCheckBoxIcon(false)}
            checkedIcon={getCheckBoxIcon(true)}
            onClick={() => this.toggleSelectRow(row)}
            checked={this.state.selectedRows.includes(row)}
          />
        </TableCell>
        <TableCell className="lead-table-data-company">
          <span className="purple-text text-hover-effect" onClick={() => this.selectSingleLead(row.id, row.type)}>{getLeadName(row)}</span>
        </TableCell>
        <TableCell className="lead-table-data-company"><span className="grey-text" onClick={() => this.getCompanyDeals(row)}>{getNullValues(capitalizeFirstLetter(row.company_name))}</span></TableCell>
        {
          getToken('partner_role') === 'admin' &&
          <TableCell className="lead-table-data-row flex-row">
            {row.partner_image_url !== null ?
              <>
                <Avatar src={row.created_by==="Builder"?null:row.partner_image_url} className="avtar-block purple-bg">
                  {getIntitals(row.created_by)}
                </Avatar>
                {capitalizeFirstLetter(row.created_by)}
              </>
              : "-"}
          </TableCell>
        }
        <TableCell className="lead-table-data-row">
          <Typography
            style={{
              textTransform: 'capitalize',
              display: 'inline-flex',
              padding: '2px 8px 1px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '32px',
              border: `1px solid ${getColorsForStatus(row.deal_status).border}`,
              background: getColorsForStatus(row.deal_status).surface,
              width: 'fit-content',
            }}
          ><label
            style={{
              color: getColorsForStatus(row.deal_status).main,
              textAlign: 'center',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '15px',
              letterSpacing: '0.12px',
              whiteSpace: 'nowrap'
            }}>{this.labelRenderer(row)}</label></Typography>
        </TableCell>
        <TableCell className="lead-table-data-row">{this.currencyCheck(row.value)}{getCurrencyValue(row.value)}</TableCell>
        <TableCell className="lead-table-data-row">{getDateFormat(row.updated_at)}</TableCell>
      </TableRow>
    ))
  }

  renderLeadInfo = () => {
    return <> {
      this.isLeadDashboardLoaded() ?
        <>
          <Box className="skeleton-wrapper">
            <TableSkeleton></TableSkeleton>
          </Box>
        </>
        :
        <>
          {
            this.state.leadInfo.empty_state === false ?
              <>
                {
                  this.state.leadInfo.leads.length > 0 ?
                    <Box style={{ minHeight: '450px' }}>
                      <TableContainer className="lead-table" component={Paper}>
                        <Table aria-label="simple table">
                          <TableHead style={{ background: '#f8f9fa', borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                            <TableRow>
                              <TableCell style={{ width: '48px' }} className="lead-table-heading checkbox-cell">
                                <Checkbox
                                  data-test-id={"btnCheckAllLeads"}
                                  size="small"
                                  indeterminate={
                                    this.getIndeterminate()
                                  }
                                  icon={getCheckBoxIcon(false)}
                                  checkedIcon={getCheckBoxIcon(true)}
                                  checked={this.state.selectAll}
                                  onChange={(e) => this.toggleSelectAll(e)}
                                />
                              </TableCell>
                              <TableCell style={{ width: '220px' }} className="lead-table-heading">NAME</TableCell>
                              <TableCell style={{ width: '180px' }} className="lead-table-heading">COMPANY</TableCell>
                              {
                                getToken('partner_role') === 'admin' &&
                                <TableCell style={{ width: '180px' }} className="lead-table-heading">CREATED BY</TableCell>
                              }
                              <TableCell style={{ width: '164px' }} className="lead-table-heading">STAGE</TableCell>
                              <TableCell style={{ width: '120px' }} className="lead-table-heading">VALUE</TableCell>
                              <TableCell style={{ width: '146px' }} className="lead-table-heading">
                                <Tooltip style={{ textAlign: 'inherit' }} title="DD/MM/YYYY" placement="top" arrow PopperProps={{
                                  style: { textAlign: 'center' },
                                  modifiers: {
                                    offset: {
                                      enabled: true,
                                      offset: '0, 8'
                                    }
                                  }
                                }}>
                                  <label>LAST UPDATED</label>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.leadInfo()}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                    :
                    <Box className="empty-data-block height-lg">
                      <Box className="empty-content">
                        <Box className="empty-img">
                          <img src={emptyAssets} />
                        </Box>
                        <Box className="content">
                          <Typography className="title-sm">No referrals found</Typography>
                          <Typography className="empty-sub-text">Try searching for something else or removing filters</Typography>
                        </Box>
                      </Box>
                    </Box>
                }
              </>
              :
              <>
                {this.emptyTableInfo()}
              </>
          }
        </>
    }
    </>
  }
  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ tabValue: newValue });
  };

  sortByInfo = () => {
    return <> {
      <div>
        <Button
          data-test-id={"selectSortMenu"}
          type="button"
          className="sort-deal-button"
          id="sort-button"
          aria-haspopup="true"
          disableRipple
          onClick={(event) => this.handleSortClick(event)}
        >
          <Typography className="select-stage-text">
            Sort by
          </Typography>
          <Typography className="text-transform sort-value-text">
            {this.state.sortValue}
            <label className="dropdown-arrow">
              <img src={downArrow} /></label>
          </Typography>
        </Button>
        <Menu
          style={{
            top: '52px',
            borderRadius: '8px',
          }}
          id="sort-menu"
          aria-labelledby="sort-button"
          anchorEl={this.state.sortMenu}
          open={this.state.sortMenu}
          onClose={this.handleMenuClose}
          className="sort-popover"
        >
          <MenuItem id="sort-1" className="menu-item-text" onClick={() => this.handleSortClose("Latest")}>Latest {getCheckMarkIcon('latest', checkMark, this.state.sortValue)}</MenuItem>
          <MenuItem id="sort-2" className="menu-item-text" onClick={() => this.handleSortClose('Oldest')}>Oldest {getCheckMarkIcon('oldest', checkMark, this.state.sortValue)}</MenuItem>
          <MenuItem id="sort-3" className="menu-item-text" onClick={() => this.handleSortClose('A-Z')}>A-Z {getCheckMarkIcon('A-Z', checkMark, this.state.sortValue)}</MenuItem>
          <MenuItem id="sort-4" className="menu-item-text" onClick={() => this.handleSortClose('Z-A')}>Z-A {getCheckMarkIcon('Z-A', checkMark, this.state.sortValue)}</MenuItem>
        </Menu>
      </div>
    }
    </>
  }

  emptyTableInfo = () => {
    return <> {
      <Box className="empty-table-wrapper" style={{ minHeight: '380px' }}>
        <Table>
          <TableHead style={{ background: '#f8f9fa', borderTop: '1px solid #ECEDF0', borderBottom: '1px solid #ECEDF0' }}>
            <TableRow>
              <TableCell className="lead-table-heading" style={{ width: '48px' }}>
                <Checkbox
                  size="small"
                  icon={getCheckBoxIcon(false)}
                  checkedIcon={getCheckBoxIcon(true)}
                />
              </TableCell>
              <TableCell style={{ width: '220px' }} className="lead-table-heading">NAME</TableCell>
              <TableCell style={{ width: '180px' }} className="lead-table-heading">COMPANY</TableCell>
              {
                getToken('partner_role') === 'admin' &&
                <TableCell style={{ width: '180px' }} className="lead-table-heading">CREATED BY</TableCell>
              }
              <TableCell style={{ width: '164px' }} className="lead-table-heading">STAGE</TableCell>
              <TableCell style={{ width: '120px' }} className="lead-table-heading">VALUE</TableCell>
              <TableCell style={{ width: '146px' }} className="lead-table-heading">
                <Tooltip style={{ textAlign: 'inherit' }} title="DD/MM/YYYY" placement="top" arrow PopperProps={{
                  style: { textAlign: 'center' },
                  modifiers: {
                    offset: {
                      enabled: true,
                      offset: '0, 8'
                    }
                  }
                }}>
                  <label>LAST UPDATED</label>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <Box className="empty-data-block height-lg">
          <Box className="empty-content">
            <Box className="empty-img">
              <img src={emptySvg} />
            </Box>
            <Box className="content">
              <Typography className="title-sm">Get started by adding referrals</Typography>
              <Typography className="empty-sub-text">Get started by adding your first referral to this dashboard</Typography>
              <Box className="empty-btn">
                <Button data-test-id={"btnEmptyNavigateDeal"} onClick={() => this.navigateToNewDeal()} className="custom-btn primaryLight-btn btn-sm">
                  <Typography className="empty-button-text">
                    Register new referral
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    }
    </>
  }

  Pagination = () => {
    return (this.state.leadInfo.total_deals >= 10 && this.state.leadInfo.leads.length !== 0 &&
      <Box className="table-page-info-block">
        <Box className="pagination-label-wrapper">
          <Typography className="pagination-text">
            Showing {getPageCount(this.state.currentPage, this.state.leadInfo.meta.total_count)} out of {this.state.leadInfo.meta.total_count} results
          </Typography>
        </Box>
        <Box className="pagination-wrapper">
          <Pagination count={this.state.leadInfo.meta.total_pages} onChange={(event, page) => this.handlePageChange(page)} page={this.state.currentPage || 1} shape="rounded" />
        </Box>
      </Box>
    )
  }

  Calendar = () => {
    return this.state.openCalendar && (
      <div className="calander-picker">
        <div style={{ height: "390px", width: "351px" }}>
          <div>
            <Typography className="calander-range-text-label">Custom...</Typography>
            <Typography className="calander-range-text-value">{moment(this.state.dateRangeValue[0]).format('MMM D')} - {moment(this.state.dateRangeValue[1]).format('MMM D YYYY')}</Typography>
          </div>
          <hr />
          <div style={{ height: "337px" }}>
            <DateRangePicker
              data-test-id="DateRangePicker"
              isOpen={this.state.openCalendar}
              value={this.state.rangeDatePicker}
              onChange={(date)=>{this.setState({rangeDatePicker:date})}}
              className="daterange-picker-css"
            />
          </div>
          <hr />
        </div>
        <div style={webStyles.rightAlign}>
          <Button data-test-id="close-calendar" 
          onClick={() => {
            this.setState({openCalendar:false})}
            }>
            <Typography className="cal-cancel-btn">
              Cancel
            </Typography>
          </Button>
          <Button data-test-id="get-filter-data" onClick={() => {
            this.setState({
              dateRangeValue: this.state.rangeDatePicker
            },()=>{
              this.getLeads('normal')
              this.setState({openCalendar:false})
            })
          }}>
            <Typography className="cal-apply-btn">
              Apply
            </Typography>
          </Button>
        </div>
      </div>
    )
  }

  FilterOption = () => {
    const { selectMenu } = this.state;
    const selectDealOpen = Boolean(selectMenu);
    return (
      this.state.leadInfo.empty_state === false ?
        <>
          <Button
            data-test-id={"btnSelectStagesFiter"}
            className="sort-deal-button"
            id="select-button"
            aria-haspopup="true"
            onClick={(event) => this.handleSelectClick(event)}
            disableRipple
          >
            <Typography className="select-stage-text">
              Stages
            </Typography>
            <Typography className="asset-select-option-text sort-value-text">
              {getLabelByValue(leadsFilterList[this.state.stagesValue])}<label className="dropdown-arrow"> <img src={downArrow} /></label>
            </Typography>
          </Button>
          <Menu
            style={{
              top: '52px',
              borderRadius: '8px',
            }}
            id="select-menu"
            aria-labelledby="select-button"
            anchorEl={selectMenu}
            open={selectDealOpen}
            onClose={this.handleMenuClose}
            className="stages-menu-dropdown"
          >
            {Object.keys(leadsFilterList).map((key: string) => (
              leadsFilterList[key].length < 20 ?
                <MenuItem
                  className="menu-item-text"
                  data-test-id="label"
                  onClick={() => this.handleSelectClose(key)}
                  style={{ width: "100%" }}
                >
                  <div className="menu-item-label">{leadsFilterList[key]}</div>
                  {getCheckMarkIcon(key, checkMark, this.state.stagesValue)}
                </MenuItem>
                :
                <Tooltip title={leadsFilterList[key]} placement="top" arrow>
                  <MenuItem
                    className="menu-item-text"
                    data-test-id="label"
                    onClick={() => this.handleSelectClose(key)}
                    style={{ width: "100%" }}
                  >
                    <div className="menu-item-label">{leadsFilterList[key]}</div>
                    {getCheckMarkIcon(key, checkMark, this.state.stagesValue)}
                  </MenuItem>
                </Tooltip>
            ))}
          </Menu>
        </> : <></>

    )
  }

  LeadListing=()=>{
    return (
      <Grid className="leads-table-wrapper">
        <Box className="filter-block-wrapper">
          {
            this.state.selectedRows.length > 0 ?
              <Box style={webStyles.centerAlign} className="filter-inner-wrap" >
                <Box className="filter-left-block">
                  <Typography className="searchbar-text">{this.state.selectedRows.length} Deals are selected</Typography>
                </Box>
                <Box className="filter-right-block">
                  <Box className="delete-icon" onClick={() => this.confirmationDeleteModal()}>
                    <Button disableRipple className="remove-deals-btn" data-test-id={"openDeleteModal"} onClick={() => this.confirmationDeleteModal()}>
                      <Typography className="delete-deal-text">
                        <img className="mr-8" src={deleteIcon} /> <label style={webStyles.click}>Remove {this.state.selectedRows.length} Deals</label>
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
              :
              <Box style={webStyles.centerAlign} className="filter-inner-wrap">
                <Box className="filter-left-block">
                  {
                    this.state.query.length > 0 ?
                      <>
                        {this.state.leadInfo.leads?.length > 0 ?
                          <Typography className="search-results-text">{`Search results for "${this.state.query}"`}</Typography>
                          :
                          <Typography className="search-results-text">Showing 0</Typography>
                        }
                      </>
                      :
                      <>
                        <div className="display-flex">
                          <label>
                            <Typography className="open-deals-sub-text">
                              Showing {this.state.leadInfo.meta.total_count}
                            </Typography>
                          </label>                          
                        </div>
                      </>
                  }
                </Box>

                <Box className="filter-right-block">
                  <Grid container style={webStyles.rightAlign}>
                    <Grid className="selectdeal-wrapper mr-16">
                      {this.FilterOption()}
                    </Grid>
                    <Grid className="sortby-wrapper">
                      {
                        this.state.leadInfo.empty_state === false &&
                          this.state.leadInfo.leads.length ?
                            this.sortByInfo():<></>
                      }
                    </Grid>
                    <Grid className="mr-16">
                      <div>
                        <TextField
                          data-test-id={"searchLeadText"}
                          className="search-input"
                          fullWidth
                          size="small"
                          placeholder="Search"
                          variant="outlined"
                          value={this.state.searchValue}
                          onChange={(e) => this.handleSearchValue(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={searchIcon} className="ml-10" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid>
                      <Box>
                        <Tooltip title="Download CSV" placement="top" arrow>
                          <Button className="download-icon-btn" disabled={!this.state.leadInfo.leads.length}>
                            <CSVLink className="download-icon" filename="Referrals" data={this.state.csvData.data} headers={this.state.csvData.headers.map((item: any) => {
                              return { label: item.label, key: item.label }
                            })}>
                              <img src={downloadIcon} />
                            </CSVLink>
                          </Button>
                        </Tooltip>
                      </Box >
                    </Grid >
                  </Grid >
                </Box >
              </Box >
          }
        </Box>

        {this.renderLeadInfo()}
      </Grid >
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { sortMenu, selectMenu, tabValue } = this.state;
    const sortDealOpen = Boolean(sortMenu);
    const selectDealOpen = Boolean(selectMenu);
    const { calendarMenu } = this.state;
    const sortCalendarOpen = Boolean(calendarMenu);

    return (
      //Merge Engine DefaultContainer
      <Box style={webStyles.container}>
        <Grid container>
          <Grid item xs={3} md={2} className="sidebar-wrapper">
            <SideNav {...this.props.navigation} />
          </Grid>
          <Grid item xs={9} md={10} className="main-content-wrapper">
            <Grid>
              <TopNavBar currentPage={{ name: 'Referrals', path: 'leads-deals' }} />
              <Box style={webStyles.mainContainer}>
                <Box className="lead-dashboard-heading dashboard-nav-container">
                  <Box className="dashboard-heading">
                    <Box>
                      <Typography className="deal-heading-main">
                        Referrals
                      </Typography>
                      <Typography className="deal-heading-sub">
                        View and manage all your referrals here.
                      </Typography>
                    </Box>
                  </Box>
                  <Grid className="dashboard-calendar-container">
                    <Grid item style={webStyles.rightAlign}>
                      <div className="date-range-wrapper">
                        <p className="date-range-text">
                          Show data over
                        </p>
                        <div className="display-flex ml-6">
                          <Button
                            className="select-deal-button"
                            id="select-button"
                            aria-haspopup="true"
                            data-test-id={"btnCalendarMenuOpen"}
                            onClick={this.handleCalendarClick}
                            disableRipple
                          >
                            <Typography className="select-stage-text">
                              {getCalendarByValue(this.state.calanderValue)}
                              <label className="dropdown-arrow">
                                {" "}
                                <img src={downArrow} />
                              </label>
                            </Typography>
                          </Button>
                          <Menu
                            style={{
                              top: "52px",
                              borderRadius: "8px",
                            }}
                            id="select-menu"
                            aria-labelledby="select-button"
                            anchorEl={calendarMenu}
                            open={sortCalendarOpen}
                            onClose={this.handleCalendarClose}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                            PaperProps={{
                              className: 'date-range-menu-paper',
                            }}
                          >
                            {
                              calendarFilterArray.map((item: any, index: any) => {
                                return <MenuItem data-test-id={`selectCalanderMenu${index}`} key={item.value} className="menu-item-text" onClick={() => this.handleCalendarSelect(item.value)}>{item.label}{getCheckMarkIcon(item.value, checkMark, this.state.calanderValue)}</MenuItem>
                              })
                            }
                          </Menu>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Box style={webStyles.rightAlign}>
                    <Button
                      data-test-id={"btnRegisteredDealLead"}
                      variant="contained"
                      color="primary"
                      className="heading-button"
                      onClick={() => this.navigateToNewDeal()}
                    >
                      <Typography className="deal-lead-btn-text">
                        Register referral
                      </Typography>
                    </Button>
                  </Box>
                </Box>
                <Grid className="dashboard-datepicker-container" container style={{marginTop:"-4px"}}>
                  {this.Calendar()}
                </Grid>
                {
                  this.state.isLoading || this.state.isTableLoading ?
                    <Box className="chart-row-skeleton mt-1" style={{
                      display: 'grid',
                      margin: '0 -8px 16px',
                      gridTemplateColumns: '100%',
                      marginTop: '16px'
                    }}>
                      <Box className="chart-col-skeleton" style={{ height: '320px', maxWidth: '100%' }}>
                        <Box className="col-inner-wrap">
                          <Box className="chart-content-wrap" >
                            <Skeleton className="chart-title" height={26} width={150} />
                            <Skeleton style={{ height: '200px' }} className="linechart-skeleton"></Skeleton>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    :
                    !this.state.leadInfo.empty_state ?
                      <Grid className="chart-section" container>
                        <Grid className="chart-section-graph" spacing={2} item xs={12} md={12}>
                          <Tabs TabIndicatorProps={{ style: { backgroundColor: "#6200EA" } }} value={this.state.tabValue} variant="fullWidth" onChange={this.handleChange} aria-label="simple tabs example">
                            <Tab label={<TotalLeadsData count={this.state.leadInfo.total_deals} calendar={getDateRangeText(this.state.calanderValue, this.state.dateRangeValue)} />}  {...a11yProps(0)} />
                            <Tab label={<OpenLeadsData count={this.state.leadInfo.open_deals} calendar={getDateRangeText(this.state.calanderValue, this.state.dateRangeValue)} />} {...a11yProps(1)} />
                            <Tab label={<ClosedLeadsData count={`${this.state.leadInfo.total_closed_won_count}/${this.state.leadInfo.total_closed_lost_count}`} calendar={getDateRangeText(this.state.calanderValue, this.state.dateRangeValue)} />} {...a11yProps(2)} />
                          </Tabs>
                          <TabPanel value={tabValue} index={0}>
                            <div className="chart-col-inner-wrap">
                              <Grid className="line-chart">
                                <LeadsPageLineGraph data={this.state.leadInfo.total_leads_graph} dateRange={this.state.dateRangeValue} calanderValue={this.state.calanderValue} label={"Total"} />
                              </Grid>
                            </div>
                          </TabPanel>
                          <TabPanel value={tabValue} index={1}>
                            <div className="chart-col-inner-wrap">
                              <Grid className="line-chart">
                                <LeadsPageLineGraph data={this.state.leadInfo.open_lead_graph} dateRange={this.state.dateRangeValue} calanderValue={this.state.calanderValue} label={"Leads and Opportunities"} />
                              </Grid>
                            </div>
                          </TabPanel>
                          <TabPanel value={tabValue} index={2}>
                            <div className="chart-col-inner-wrap">
                              <Grid className="line-chart">
                                <LeadsPageLineGraph
                                  data1={this.state.leadInfo.total_lead_closed_won_graph}
                                  data2={this.state.leadInfo.total_lead_closed_lost_graph}
                                  dateRange={this.state.dateRangeValue}
                                  calanderValue={this.state.calanderValue}
                                  label={"Closed won/lost deals"}
                                />
                              </Grid>
                            </div>
                          </TabPanel>
                        </Grid>
                      </Grid>
                      :
                      <Box className="lead-section-one">
                        <Grid item xs={12} md={12} container className="empty-lead-block">
                          <Grid className="lead-section-one-grid-empty-one" item xs={6} md={6}>
                            <Box>
                              <Typography className="grid-text-empty-heading">Get started by adding referrals</Typography>
                            </Box>
                            <Box>
                              <Typography className="grid-text-empty-sub-heading">Add more referrals to this dashboad and soon the data will be generated for you to view</Typography>
                            </Box>
                          </Grid>
                          <Grid className="lead-section-one-grid-empty-two" item xs={6} md={6}>
                            <img src={leadsGraphBars} />
                          </Grid>
                        </Grid>
                      </Box>
                }

                {this.LeadListing()}

                {this.Pagination()}

              </Box >
            </Grid >
          </Grid >
        </Grid >
        <Modal
          open={this.state.deleteModal}
          onClose={this.closeDeleteModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="custom-modal confirmation-modal modal-sm"
          BackdropProps={{
            className: "custom-modal-backdrop"
          }}
        >
          <Box className="modal-body">
            <Box>
              <IconButton data-test-id={"closeDeleteModal"} disableRipple className="close-btn" onClick={() => this.closeDeleteModal()}><img src={closeIcon} alt="close" /></IconButton>
              <Typography variant="h4">
                Are you sure you would like to remove {this.state.selectedRows.length}
                {" "}deals from the table?
              </Typography>
              <Typography className="delete-label-text">
                Removing this lead does not necessarily remove from our CRM but your account will be unmapped.
              </Typography>
            </Box>
            <Box className="mt-1 btn-wrap">

              <Button
                data-test-id="btnCloseDeleteModal"
                onClick={() => this.closeDeleteModal()}
                fullWidth
                size="small"
                variant="contained"
                type="button"
                className="custom-btn">
                Cancel
              </Button>
              <Button
                data-test-id="btnRemoveDeals"
                onClick={() => this.deleteLeads()}
                fullWidth
                size="small"
                variant="contained"
                type="button"
                className="custom-btn red-btn">
                Remove
              </Button>
            </Box>
          </Box >
        </Modal >
      </Box >
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    background: '#F8F9FA',
    height: '100vh',
    overflow: 'auto',
  },
  mainContainer: {
    padding: '16px 0'
  },
  tableContainer: {
    marginTop: 16,
    marginBottom: 16
  },
  centerAlign: {
    alignItems: 'center'
  },
  rightAlign: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    display: 'flex'
  },
  click: {
    cursor: 'pointer'
  }
};
// Customizable Area End
